import React from 'react';
import Router from './routes/Router';
import './assets/styles/index.scss';

const App = () => {
  const documentHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', documentHeight);
  documentHeight();

  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
};

export default App;
