import {useTranslation} from 'react-i18next';
import Finy1 from '../../assets/images/finy1.png';
import Finy2 from '../../assets/images/finy2.png';
import Finy3 from '../../assets/images/finy3.png';
import {Button, Col, Row} from 'antd';

const FeaturesSection = () => {
  const {t} = useTranslation();
  return (
    <div className="bg-surface py-32 mt-40 text-center">
      <div className="text-surfaceDark text-2xl font-medium text-center">
        {t('header.features')}
      </div>
      <div className="text-center text-surfaceMedium mt-2">        {t('features.users')}.</div>
      <div className="mt-32 container mx-auto">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} lg={8}>
            <div className="p-6 bg-primary rounded-2xl w-full relative overflow-hidden finy-item text-start h-[450px]">
              <div className="text-xl text-white font-bold">
                {t('features.farmer.title')}
              </div>
              <div className="text-md text-light font-bold mt-1">
                {t('features.farmer.desc')}
              </div>
              <img src={Finy1} alt="finy" className="finy-img" />
            </div>
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <div className="p-6 bg-primary rounded-2xl w-full relative overflow-hidden finy-item text-start h-[450px]">
              <div className="text-xl text-white font-bold">
                {t('features.audit.title')}
              </div>
              <div className="text-md text-light font-bold mt-1">
                {t('features.audit.desc')}
              </div>
              <img src={Finy2} alt="finy" className="finy-img" />
            </div>
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <div className="p-6 bg-primary rounded-2xl w-full relative overflow-hidden finy-item-desktop text-start h-[450px]">
              <div className="text-xl text-white font-bold">
                {t('features.admin.title')}
              </div>
              <div className="text-md text-light font-bold mt-1">
                {t('features.admin.desc')}
              </div>
              <img src={Finy3} alt="finy" className="finy-img-desktop" />
            </div>
          </Col>
        </Row>
      </div>
      <Button
        type="ghost"
        size="large"
        shape="round"
        className="mt-20 bg-primaryHigh text-white"
      >
        <span className="text-sm font-bold">{t('reports')}</span>
      </Button>
    </div>
  );
};

export default FeaturesSection;
