import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import Mock from '../../assets/images/mockup.png';
import Apple from '../../assets/images/icons/apple.svg';
import Android from '../../assets/images/icons/android.svg';
import Demo from '../../assets/images/icons/demo.svg';
import StateBank from '../../assets/images/hamtragch/statebank.png';
import Lend from '../../assets/images/hamtragch/lendmn.png';
// import Zeel from '../../assets/images/hamtragch/zeelmn.png';
// import Numur from '../../assets/images/hamtragch/numur.png';
import Tarimal from '../../assets/images/hamtragch/tarimal.png';
import SFCS from '../../assets/images/hamtragch/sfcs.png';

const HeroSection = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="container mx-auto my-4">
        <div className="hero-container px-0 sm:px-[6vw] lg:px-[8vw] py-[12vw] sm:py-[6vw] lg:py-[8vw]">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={14}>
              <div className="text-3xl sm:text-1.5xl lg:text-4xl text-black sm:text-white max-w-[450px]">
                {t('hero.title')}
              </div>
              <div className="text-surfaceMedium sm:text-light max-w-[450px] mt-8">
                {t('hero.desc')}
              </div>
              <div className="mt-20 flex flex-wrap justify-center lg:justify-start gap-x-4 sm:gap-x-6 gap-y-4">
                <a className="bg-darkGray rounded-full px-6 flex items-center gap-x-2 py-2" href='https://apps.apple.com/mn/app/finy-farmer/id6447514604' target='_blank'>
                  <img src={Apple} alt="apple" />
                  <div className="text-start">
                    <div className="text-2xs text-surgaceGreen leading-none">
   
                    </div>
                    <div className="text-sm text-surgaceGreen font-bold">
                      {t('hero.appstore')}
                    </div>
                  </div>
                </a>
                <a className="bg-darkGray rounded-full px-6 flex items-center gap-x-2 py-2" href='https://play.google.com/store/apps/details?id=mn.nomads.finy' target='_blank'>
                  <img src={Android} alt="apple" />
                  <div className="text-start">
                    <div className="text-2xs text-surgaceGreen leading-none">
 
                    </div>
                    <div className="text-sm text-surgaceGreen font-bold">
                      {t('hero.android')}
                    </div>
                  </div>
                </a>
                <a className="bg-primary rounded-full px-6 flex items-center gap-x-2 py-2" href='https://apps.apple.com/mn/app/finy-audit/id6447866509' target='_blank'>
                  <img src={Demo} alt="apple" />
                  <div className="text-start">
                    <div className="text-2xs text-surgaceGreen leading-none">
            
                    </div>
                    <div className="text-sm text-surgaceGreen font-bold">
                      {t('hero.auditapp')}
                    </div>
                  </div>
                </a>
              </div>
            </Col>
            <Col xs={24} sm={10}>
              <div className="mock-container">
                <img
                  src={Mock}
                  className="w-[50vw] sm:w-[18vw] max-w-[270px] mx-auto"
                  alt="mock"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="border-b border-dashed">
        <div className="container mx-auto flex justify-between xl:justify-around items-end py-10 overflow-y-scroll gap-x-10">
          <img src={StateBank} className="h-[80px]" alt="state" />
          <img src={Lend} className="h-[70px]" alt="lend" />
          {/* <img src={Zeel} className="h-[70px]" alt="zeel" /> */}
          {/* <img src={Numur} className="h-[70px]" alt="numur" /> */}
          <img src={Tarimal} className="h-[80px]" alt="tarimal" />
          <img src={SFCS} className="h-[80px]" alt="sfcs" />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
