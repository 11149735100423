export const theme = {
  token: {
    colorPrimary: '#3D8F40',
    colorWarning: '#DE7428',
    lineType: 'solid',
    colorBgLayout: '#fff',
    controlHeight: 40,
    controlHeightSM: 32,
    controlHeightLG: 48,
    fontSize: 15,
    fontSizeSM: 12,
  },
};
