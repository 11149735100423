import {Header as HeaderComponent} from 'antd/es/layout/layout';
import Logo from '../assets/images/logo.png';
import Globe from '../assets/images/icons/globe.svg';
import Search from '../assets/images/icons/search.svg';
import Close from '../assets/images/icons/close.svg';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {Button, ConfigProvider, Drawer, Input, message} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {firestore} from '../firebase';
import * as _ from 'lodash';

const navItems = ['about', 'industry', 'features', 'workwithus'];

const Header = () => {
  const {t, i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState('hero');
  const [loading, setLoading] = useState(false);
  const [certifNumber, setCertifNumber] = useState('');
  const toggleDrawer = () => setOpen(!open);

  const changeLanguage = () =>
    i18n.changeLanguage(i18n.language === 'mn' ? 'en' : 'mn');

  const handleNav = (id) => {
    const element = document.getElementById(`#${id}`);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
      setCurrent(id);
      setOpen(false);
    }
  };

  const searchCertificate = async () => {
    if (loading || _.isEmpty(certifNumber)) return;
    setLoading(true);
    const certificate = await firestore
      .collection('grantedCertificates')
      .where('type', 'in', ['local', 'finy'])
      .where('certificateNumber', '==', certifNumber)
      .get();
    setLoading(false);
    if (!certificate.empty)
      window.open(
        `https://audit.finy.app/certificate/view/${certificate.docs[0].id}`,
        '_blank',
        'noopener,noreferrer',
      );
    else {
      message.info(`${certifNumber} дугаартай сертификат олдсонгүй.`);
    }
  };

  return (
    <HeaderComponent
      style={{
        position: 'sticky',
        top: 0,
      }}
      className="w-full flex items-center bg-white h-[64px] md:h-[100px] px-0 z-10"
    >
      <div className="container mx-auto flex items-center gap-x-6 lg:gap-x-12">
        <img
          src={Logo}
          className="h-[24px] lg:h-[40px] cursor-pointer"
          alt="logo"
          onClick={() => handleNav('hero')}
        />
        <div className="flex-1">
          <div className="hidden md:flex flex-wrap gap-x-2 lg:gap-x-4 my-6 font-bold">
            {navItems.map((i) => (
              <div
                key={`nav-${i}`}
                onClick={() => handleNav(i)}
                className="nav-item group px-4"
              >
                <div
                  className={`leading-none group-hover:text-surfaceMedium ${
                    current !== i && 'text-grey'
                  }`}
                >
                  {t(`header.${i}`)}
                </div>
                <div
                  className={`nav-pointer mt-0 group-hover:mt-4 group-hover:visible ${
                    current === i ? 'active' : 'invisible'
                  }`}
                />
              </div>
            ))}
            <div className="items-center hidden lg:flex flex-1 justify-end">
              <img src={Search} alt="search" />
              <ConfigProvider
                theme={{
                  token: {
                    controlOutline: '#fff',
                    controlOutlineWidth: 0,
                  },
                }}
              >
                <Input
                  value={certifNumber}
                  onChange={(e) => setCertifNumber(e.target.value)}
                  placeholder={t('header.certificate')}
                  className="border-none outline-none active:outline-none focus:outline-none w-auto"
                />
              </ConfigProvider>
              <Button
                type="ghost"
                onClick={searchCertificate}
                loading={loading}
                className="rounded-full bg-primary text-white px-6 font-bold"
              >
                {t('header.search')}
              </Button>
            </div>
          </div>
        </div>
        <Button
          type="link"
          className="bg-surface rounded-full flex items-center gap-x-2 font-bold"
          onClick={changeLanguage}
        >
          <img src={Globe} alt="lan" />
          <span className="text-surfaceDark">
            {i18n.language === 'mn' ? 'EN' : 'MN'}
          </span>
        </Button>
        <Button
          type="ghost"
          icon={<MenuOutlined />}
          className="block md:hidden"
          onClick={toggleDrawer}
        />
      </div>
      <Drawer
        title={<img src={Logo} className="h-[24px] mx-5" alt="logo" />}
        placement="right"
        closeIcon={<img src={Close} alt="logo" />}
        onClose={toggleDrawer}
        open={open}
      >
        <div className="flex flex-col items-start gap-x-2 lg:gap-x-4 my-6 font-bold">
          {navItems.map((i) => (
            <div
              key={`nav-${i}`}
              onClick={() => handleNav(i)}
              className="nav-item-drawer group px-4 pt-[8px]"
            >
              <div
                className={`nav-pointer-drawer mr-0 group-hover:mr-4 group-hover:visible ${
                  current === i ? 'active' : 'invisible'
                }`}
              />
              <div
                className={`leading-none group-hover:text-surfaceMedium ${
                  current !== i && 'text-grey'
                }`}
              >
                {t(`header.${i}`)}
              </div>
            </div>
          ))}
          <div className="flex items-center justify-center w-full mt-20">
            <img src={Search} alt="search" />
            <ConfigProvider
              theme={{
                token: {
                  controlOutline: '#fff',
                  controlOutlineWidth: 0,
                },
              }}
            >
              <Input
                value={certifNumber}
                onChange={(e) => setCertifNumber(e.target.value)}
                placeholder={t('header.certificate')}
                className="border-none outline-none active:outline-none focus:outline-none w-auto"
              />
            </ConfigProvider>
          </div>
          <Button
            type="ghost"
            onClick={searchCertificate}
            loading={loading}
            className="rounded-full bg-primary text-white font-bold mx-auto w-64 mt-4"
          >
            {t('header.search')}
          </Button>
        </div>
      </Drawer>
    </HeaderComponent>
  );
};

export default Header;
