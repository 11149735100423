import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import mn from './locales/mn.json';
import en from './locales/en.json';

const resources = {
  mn: {
    translation: mn,
  },
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  returnNull: false,
  resources,
  lng: 'mn',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
