import {Routes, Route} from 'react-router-dom';
import {publicRoutes} from './routes';
import Layout from '../layouts/Main.layout';

const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={<route.component />} />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
