import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import Goal from '../../assets/images/zorilgo.png';
import Vision from '../../assets/images/vision.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TeamMember from '../../components/TeamMember';
import {teamMembers, teamMembersEn} from '../../constants/data';

const responsive = {
  desktop: {
    breakpoint: {max: 5000, min: 1200},
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {max: 1199, min: 768},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 767, min: 0},
    items: 1,
  },
};

const AboutSection = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <div className="container mx-auto pt-32 pb-20">
        <Row gutter={[16, 28]}>
          <Col xs={24} md={24} xl={10}>
            <div className="text-2xl font-bold text-surfaceDark">
              {t('header.about')}
            </div>
            <div className="text-lg text-surfaceMedium mt-10 leading-[36px] pr-0 lg:pr-16 text-justify">
              {t('about.desc')}
            </div>
          </Col>
          <Col xs={24} md={12} xl={7}>
            <div className="border border-stroke p-8 rounded-3xl h-full shadow-card">
              <div className="h-[100px] w-[100px] bg-primarySoft rounded-full flex-center">
                <img
                  src={Goal}
                  alt="goal"
                  className="h-[60px] w-[60px] -mt-1"
                />
              </div>
              <div className="text-lg font-bold text-surfaceDark mt-8 ">
                {t('about.goaltitle')}
              </div>
              <div className="text-surfaceMedium mt-4 leading-[28px] text-justify">
                {t('about.goaldesc')}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} xl={7}>
            <div className="border border-stroke p-8 rounded-3xl h-full shadow-card">
              <div className="h-[100px] w-[100px] bg-primarySoft rounded-full flex-center">
                <img
                  src={Vision}
                  alt="goal"
                  className="h-[60px] w-[60px] -mt-1"
                />
              </div>
              <div className="text-lg font-bold text-surfaceDark mt-8">
                {t('about.visiontitle')}
              </div>
              <div className="text-surfaceMedium mt-4 leading-[28px] text-justify">
                {t('about.visiondesc')}
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-48 text-surfaceDark text-2xl font-medium text-center">
          {t('about.teamtitle')}
        </div>
        <div className="text-center text-surfaceMedium mt-2">
        {t('about.teamdesc')} 
        </div>
      </div>
      <div className="w-screen overflow-hidden">
        <Carousel
          className="hidden sm:flex -mx-28"
          responsive={responsive}
          arrows={false}
          centerMode={true}
          infinite={true}
        >
          {(i18n.language === 'mn' ? teamMembers : teamMembersEn).map(
            (m, i) => (
              <TeamMember member={m} key={`member-cs-${i}`} />
            ),
          )}
        </Carousel>
        <div className="block sm:hidden">
          {(i18n.language === 'mn' ? teamMembers : teamMembersEn).map(
            (m, i) => (
              <TeamMember member={m} key={`member-list-${i}`} />
            ),
          )}
        </div>
      </div>
    </>
  );
};

export default AboutSection;
