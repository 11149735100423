import {compile} from 'path-to-regexp';
import HomeScreen from '../pages/home/Index';

const Routes = {
  home: '/',
  compilePath(path, param) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};

const publicRoutes = [{path: Routes.home, component: HomeScreen}];
export {publicRoutes};
