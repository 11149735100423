import {Button, Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import Collaborator from '../../components/Collaborator';

const WorkWithUs = () => {
  const {t} = useTranslation();

  const handleNav = (id) => {
    const element = document.getElementById(`#${id}`);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <div>
      <div className="workWithUs-header "></div>
      <div className="bg-secondarySoft py-20">
        <div className="container mx-auto text-center">
          <div className="text-surfaceDark text-2xl font-medium text-center mb-16">
            {t('workwithus.title')}
          </div>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} xl={6}>
              <Collaborator
                data={{
                  title: t('workwithus.bank.title'),
                  desc: t('workwithus.bank.desc'),
                }}
              />
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <Collaborator
                data={{
                  title: t('workwithus.shop.title'),
                  desc: t('workwithus.shop.desc'),
                }}
              />
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <Collaborator
                data={{
                  title: t('workwithus.certification.title'),
                  desc: t('workwithus.certification.desc'),
                }}
              />
            </Col>
            <Col xs={24} sm={12} xl={6}>
              <Collaborator
                data={{
                  title: t('workwithus.farmer.title'),
                  desc: t('workwithus.farmer.desc'),
                }}
              />
            </Col>
          </Row>
          <Button
            type="ghost"
            shape="round"
            className="mt-16 bg-primary text-white w-64"
            onClick={() => handleNav('footer')}
          >
            <span className="text-sm font-bold">{t('contact')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
