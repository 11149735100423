import AboutSection from './AboutSection';
import FeaturesSection from './FeaturesSection';
import Help from './Help';
import HeroSection from './HeroSection';
import IndustrySection from './IndustrySection';
import WorkWithUs from './WorkSection';

const HomeScreen = () => {
  return (
    <div>
      <div id="#hero">
        <HeroSection />
      </div>
      <div id="#about">
        <AboutSection />
      </div>
      <div id="#industry">
        <IndustrySection />
      </div>
      <div id="#features">
        <FeaturesSection />
      </div>
      <div id="#workwithus">
        <WorkWithUs />
      </div>
      <Help />
    </div>
  );
};

export default HomeScreen;
