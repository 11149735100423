import {Layout} from 'antd';
import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
const {Content} = Layout;

const MainLayout = () => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default MainLayout;
