import {useTranslation} from 'react-i18next';
import Standart1 from '../../assets/images/standart1.png';
import Standart2 from '../../assets/images/standart2.png';
import Standart3 from '../../assets/images/standart3.png';

const IndustrySection = () => {
  const {t} = useTranslation();
  return (
    <div className="container mx-auto pt-32">
      <div className=" text-surfaceDark text-2xl font-medium text-center">
        {t('industrytitle')}
      </div>
      <div className="text-center text-surfaceMedium mt-2 mb-16">
        {t('industrydesc')}
      </div>
      <div className="flex justify-center">
        <div className="industry-card overflow-hidden">
          <img src={Standart1} className="h-full" alt="s1" />
        </div>
        <div className="industry-card overflow-hidden -ml-6">
          <img src={Standart2} className="h-full" alt="s1" />
        </div>
        <div className="industry-card overflow-hidden -ml-6">
          <img src={Standart3} className="h-full" alt="s1" />
        </div>
      </div>
    </div>
  );
};

export default IndustrySection;
