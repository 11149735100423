export const teamMembers = [
  {
    name: 'M.Чимэгсайхан',
    position: 'CEO & Business development',
    image: '/images/team/Portrait1.png',
    bio: [
      'Боловсрол, Хүний эрх, Судалгаа, Нийгмийн гарааны бизнес, ЖД бизнес болон Төрийн бус байгууллагуудад 10 жил ажиллсан туршлагатай',
      'Агролли газар тариалан эрхлэгчдэд зориулсан аппыг Монгол, Энэтхэг, Бразил улсуудад гаргасан',
      'АНУ-ын Фулбрайт тэтгэлэг Пэйс их сургууль Бизнесийн удирдлага, Технологид суурилсан бизнес чиглэлээр мастер 2018-2020',
    ],
  },
  {
    name: 'Д.Өлзийбүрэн',
    position: 'CTO',
    image: '/images/team/Portrait2.png',
    bio: [
      'Апп, систем хөгжүүлэлтээр 9 гаруй жил ажиллсан туршлагатай, 2014 онд Гаалийн Татварын системийн хөгжүүлэлтийг эхлүүлж ажиллаж байсан',
      'Агролли Монгол аппын хамтран үүсгэн байгуулагч, хөгжүүлэгч',
      'Компьютерийн шинжлэх ухаан, хиймэл оюун ухаан машин лэрнинг чиглэлээр мастер хамгаалсан',
      'Франц улсын засгийн газрын тэтгэлэг 2017-2019',
    ],
  },
  {
    name: 'Д.Золзаяа',
    position: 'CFO',
    image: '/images/team/Portrait3.png',
    bio: [
      '“Зээл эм эн платформ” XXК-д зээлийн мэргэжилтэн, "Монре Файнаншиал XXК-д Санxүүгийн менежер, Xөрөнгө оруулалтын менежер тус тус ажиллаж байсан. ',
      'Санxүү удирдлагын мэргэжлээр бакалавр зэрэгтэй',
      'Хоцума Олон Улсын Сургууль 2013-2014',
      'Бээжингийн Хэл Соёл Шинжлэлийн Их Сургууль 2014-2017 ',
    ],
  },
  {
    name: 'Г.Өнөржаргал',
    position: 'COO',
    image: '/images/team/Portrait4.png',
    bio: [
      'Стандартчилал, тохирлын үнэлгээний салбарт 20 жил ажилласнаас, салбарын хууль эрх зүйн бодлогын баримт бичгийг боловсруулах, хэрэгжилтийг ханган ажиллахад стандартчилал, тохирлын үнэлгээний бодлогоор дэмжлэг үзүүлж ирсэн туршлагатай',
      'Төрийн захиргааны менежментийн магистр зэрэгтэй',
      'Эрх зүйч мэргэжитэй',
    ],
  },
];
export const teamMembersEn = [
  {
    name: 'Chimegsaihan M.',
    position: 'CEO & Business development',
    image: '/images/team/Portrait1.png',
    bio: [
      '10 years of experience in social enterprise, human rights, education, research, small and large business, and non-profit',
      'Agrolly, farming app (launched in India, Brazil, Mongolia) co-founder',
      'Fulbright, Pace university MBA 2018-2020',
    ],
  },
  {
    name: 'Ulziiburen D.',
    position: 'CTO',
    image: '/images/team/Portrait2.png',
    bio: [
      '9 years full stack developer, Custom Office Tax Payment System in 2014',
      'Agrolly Mongolia app co-founder in 2021',
      'MSc in Computer Science & Machine Learning',
      'French Government scholarship 2017-2019',
    ],
  },
  {
    name: 'Zolzaya D.',
    position: 'CFO',
    image: '/images/team/Portrait3.png',
    bio: [
      '6 years of experience in the field of Fintech and Insurance sector as well as in Investment',
      'BA in Economic and Finance',
      'Hotsuma International School 2013-2014',
      'Beijing Language and Cultural University 2014-2017',
    ],
  },
  {
    name: 'Unurjargal G.',
    position: 'COO',
    image: '/images/team/Portrait4.png',
    bio: [
      '20 years of experience in standardization and compliance sector. Has an expansive practical experience in the field of policy development and implementation.',
      'MSc in Public policy administration',
      'BA in law',
    ],
  },
];

export const helpData = [
  {
    title: 'Зээл хүсэх 7 алхам',
    desc: [
      'Алхам 1: Фини аппыг татаж суулгах',
      'Алхам 2: Фини апп дээр өөрийн мэдээллийг үүсгэх',
      'Алхам 3: Зээл цэс рүү орох',
      'Алхам 4: Зээлийн хүсэлт илгээх товч дарах',
      'Алхам 5: Талбайн түүх, Үүрэг амлалтын гэрээ хийх',
      'Алхам 6: Банк санхүүгийн байгууллагын шаардсан бичиг баримтыг өгч зээлээ судлуулах',
      'Алхам 7: Зээлээ авах',
    ],
  },
  {
    title: 'Зээл хэрхэн авах вэ?',
    desc: [
      'Фини аппыг татаж авч суулгаад Зээл гэсэн цэс рүү ороод шаардлагатай зүйлсийг бөглөөд зээлийн хүсэлтээ явуулаарай.',
    ],
  },
  {
    title: 'Ногоон зээл авахад юу юу шаардлагатай вэ?',
    desc: [
      'Үүрэг амлалтын гэрээг Баталгаажуулалтын байгууллагын ажилтантай хийж талбайн түүхээ мөн баталгаажуулснаар банк санхүүгийн байгууллагад таны хүсэлтийг явуулна. Банкны тавигдах цөөн тооны бичиг баримтыг та мөн цуглуулах шаардлагатай. Фини апп дээрээс дэлгэрэнгүй мэдээллийг харна уу.',
    ],
  },
  {
    title: 'Баталгаажуулалтгүйгээр ногоон зээлийн хүсэлт явуулж болох уу?',
    desc: [
      'Санаа зоволтгүй. Та баталгаажуулалт хийлгээгүй эсвэл яг одоо хийлгэх боломжгүй байсан ч зээлийн хүсэлт явуулах боломжтой. Үүрэг амлалтын гэрээ хийн 3-6 сарын дотор баталгаажуулалтын байгууллагатай холбогдон зээлийн дараа баталгаажуулалтаа эхлүүлээд явж болно.',
    ],
  },
];

export const helpDataEn = [
  {
    title: '7 simple steps to get started',
    desc: [
      'Step 1: Download the Finy app on App store or Google store',
      'Step 2: Create yout account on the app',
      'Step 3: Go to the Loan feature',
      'Step 4: Start your loan process',
      'Step 5: Contract the certification body',
      'Step 6: Provide documents to the lender',
      'Step 7: Receive loan',
    ],
  },
  {
    title: 'How can I get a loan?',
    desc: [
      'Download the Finy app and go to the Loan section and then start the process.',
    ],
  },
  {
    title: 'What do I need to have to get a green loan?',
    desc: [
      'Validate your record keeping on the app, GAP or other standard certification or least the contract with the verification body, other necessary documents required by the banks.',
    ],
  },
  {
    title: 'Can I apply for the loan without any certification?',
    desc: [
      'You can apply for the loan but the verification company will visit you and ask you to make a simple contract on which you pledge to initiate the certification process in a few months and validate your last 2 years of farming history. Go to the Finy app to find more.',
    ],
  },
];
