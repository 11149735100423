import FB from '../assets/images/icons/fb.svg';
import Instagram from '../assets/images/icons/inst.svg';
import Lin from '../assets/images/icons/li.svg';
import Down from '../assets/images/icons/down.svg';
import Up from '../assets/images/icons/up.svg';
import Leaf from '../assets/images/leaf.png';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const TeamMember = ({member}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="text-center m-4">
      <div className="p-8 pb-0 border border-stroke rounded-3xl flex items-center flex-col shadow-card">
        <img src={member.image} alt="profile" className="h-[100px]" />
        <div className="text-lg font-bold text-surfaceDark mt-8">
          {member.name}
        </div>
        <div className="text-xs text-primary bg-primarySoft px-4 py-1 mt-2 rounded-md">
          {member.position}
        </div>
        <div className="flex gap-x-5 mt-5 mb-8">
          <img src={FB} alt="fb" />
          <img src={Instagram} alt="ins" />
          <img src={Lin} alt="lin" />
        </div>
        <div
          className={`w-full gap-y-5 member-desc ${
            isOpen ? 'member-desc-expanded' : ''
          }`}
        >
          <div className="h-1 border-t border-dashed mb-6"></div>
          {member.bio.map((b, i) => (
            <div key={`bio-${i}`} className="flex items-center gap-4 mb-6">
              <img src={Leaf} alt="leaf" className="h-5" />
              <div className="flex-1 text-start text-surfaceMedium text-sm font-medium">
                {b}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={toggleOpen}
        className="bg-stroke flex justify-center rounded-b-xl text-surfaceMedium w-36 py-1 mx-auto text-sm gap-x-1 cursor-pointer"
      >
        {isOpen ? t('less') : t('more')}
        {isOpen ? <img src={Up} alt="fb" /> : <img src={Down} alt="fb" />}
      </div>
    </div>
  );
};

export default TeamMember;
