import {useTranslation} from 'react-i18next';
import Illustration from '../../assets/images/Iillustration.png';
import {helpData, helpDataEn} from '../../constants/data';
import {Collapse} from 'antd';

const Help = () => {
  const {t, i18n} = useTranslation();

  return (
    <div>
      <img src={Illustration} alt="ill" className="bg-secondarySoft" />
      <div className="bg-primarySoft py-32">
        <div className="max-w-[570px] mx-auto container">
          <div className="text-surfaceDark text-2xl font-medium text-center mb-10">
            {t('help')}
          </div>
          <Collapse
            items={(i18n.language === 'mn' ? helpData : helpDataEn).map(
              (d, i) => ({
                key: i,
                label: d.title,
                children: d.desc.map((desc, k) => (
                  <p
                    key={`help-${i}-${k}`}
                    className="text-surfaceMedium font-medium mb-1"
                  >
                    {desc}
                  </p>
                )),
              }),
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
