import {Button, Col, Input, Row} from 'antd';
import Logo from '../assets/images/logo.png';
import Globe from '../assets/images/icons/globe.svg';
import {useTranslation} from 'react-i18next';

const Footer = () => {
  const {t, i18n} = useTranslation();
  const changeLanguage = () =>
    i18n.changeLanguage(i18n.language === 'mn' ? 'en' : 'mn');

  return (
    <div id="#footer" className="container mx-auto py-16">
      <div className="flex justify-between border-b border-dashed pb-16">
        <img
          src={Logo}
          className="h-[24px] lg:h-[40px] cursor-pointer"
          alt="logo"
        />
        <Button
          type="link"
          className="bg-surface rounded-full flex items-center gap-x-2 font-bold"
          onClick={changeLanguage}
        >
          <img src={Globe} alt="lan" />
          <span className="text-surfaceDark">
            {i18n.language === 'mn' ? 'EN' : 'MN'}
          </span>
        </Button>
      </div>
      <div className="py-16 border-b border-dashed">
        <Row gutter={[20, 28]}>
          <Col xs={24} sm={24} lg={9} xl={11}>
            <div className="text-xl text-surfaceDark">
            {t('footer.followournewsletter')}
            </div>
            <div className="text-surfaceMedium mt-4 leading-[28px]">
            {t('footer.followournewsletterbody')}
            </div>
            <div className="flex gap-x-5 mt-6">
              <Input
                placeholder={t('footer.email')}
                className="border-none rounded-full bg-surface text-md font-bold w-64"
              />
              <Button
                type="ghost"
                className="bg-primary rounded-full text-white text-md font-bold"
              >
                  {t('footer.subscribe')}
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={10} lg={7} xl={5}>
            <div className="text-xl text-surfaceDark"> {t('footer.location')}</div>
            <div className="text-surfaceMedium mt-4 leading-[28px]">
            {t('footer.address')}
            </div>
          </Col>
          <Col xs={24} sm={7} lg={4} xl={4}>
            <div className="text-xl text-surfaceDark">{t('footer.phone')}</div>
            <div className="text-surfaceMedium mt-4 leading-[28px]">
              +976 - 88181847 <br />
              +976 - 94998976
            </div>
          </Col>
          <Col xs={24} sm={7} lg={4} xl={4}>
            <div className="text-xl text-surfaceDark">{t('footer.email')}</div>
            <div className="text-surfaceMedium mt-4 leading-[28px]">
              info@finy.app
            </div>
          </Col>
        </Row>
      </div>
      <div className="pt-16 flex flex-wrap justify-between gap-y-8">
        <div className="flex flex-wrap gap-8 w-full md:w-auto justify-center md:justify-start">
          <div className="text-sm text-surfaceMedium w-full sm:w-auto text-center sm:text-start">
            © 2023 Nomads Agritech Innovations LLC
          </div>
          <a
            className="text-sm text-surfaceMedium cursor-pointer"
            href="https://cdn.finy.app/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
  {t('footer.termscond')}
          </a>
          <div className="text-sm text-surfaceMedium cursor-pointer">
          {t('footer.privacy')}
          </div>
        </div>
        <div className="flex gap-8 w-full md:w-auto justify-center md:justify-end">
          <a
            className="text-sm text-surfaceMedium cursor-pointer"
            href="https://www.facebook.com/Finyapp/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            className="text-sm text-surfaceMedium cursor-pointer"
            href="https://www.instagram.com/finy_app"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <div className="text-sm text-surfaceMedium cursor-pointer">
            Twitter
          </div>
          <div className="text-sm text-surfaceMedium cursor-pointer">
            Linkedin
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
