const Collaborator = ({data}) => {
  return (
    <div className="rounded-3xl shadow-card hover:shadow-activeCard hover:border-secondary border-[2px] bg-white border-transparent p-7 h-full text-start">
      <div className="text-xl text-surfaceDark h-20 sm:h-24 lg:h-[28]">
        {data.title}
      </div>
      <div className="text-sm text-surfaceMedium">{data.desc}</div>
    </div>
  );
};

export default Collaborator;
